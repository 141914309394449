import { FC } from 'react';

import { Link } from '@common/atoms/Link/Link';
import { ModuleScheduleItem, PlatformID } from '@common/clients/api';
import { BettingDisclaimer, LayoutOptions, LogoVariant } from '@web/atoms/BettingDisclaimer';
import { TagElement } from '@web/atoms/TagElement';

import { IconBookmakerLogo } from './IconBookmakerLogo';

import styles from '../ArticlePromoItem.module.scss';

interface ArticlePromoProps {
    moduleScheduleItem: ModuleScheduleItem;
    url: string;
    icon: string;
    platform?: PlatformID;
}

export const ArticlePromo: FC<ArticlePromoProps> = ({ moduleScheduleItem, url, icon, platform }) => {
    const hasTag = moduleScheduleItem.tag;
    return (
        <>
            <div className={styles['inner-container']} data-tracker-name={moduleScheduleItem.module}>
                {platform === PlatformID.VI || !hasTag ? (
                    <IconBookmakerLogo
                        icon={icon || ''}
                        url={url || ''}
                        moduleScheduleItem={moduleScheduleItem}
                    />
                ) : (
                    <Link href={url || ''} isSponsored>
                        <TagElement item={moduleScheduleItem ?? undefined} />
                    </Link>
                )}
                <div className={styles.content}>
                    <Link href={url} isSponsored>
                        <h4>{moduleScheduleItem.text}</h4>
                    </Link>
                    {platform === PlatformID.VI ? (
                        <BettingDisclaimer
                            layoutOptions={LayoutOptions.DISCLAIMER_ONLY}
                            logoVariant={LogoVariant.TRANSPARENT}
                        />
                    ) : null}
                </div>
                {hasTag && !(platform === PlatformID.VI) ? (
                    <IconBookmakerLogo
                        icon={icon || ''}
                        url={url || ''}
                        moduleScheduleItem={moduleScheduleItem}
                    />
                ) : null}
            </div>
            {platform === PlatformID.VI ? (
                <BettingDisclaimer layoutOptions={LayoutOptions.SETTINGS_ONLY} />
            ) : (
                <BettingDisclaimer />
            )}
        </>
    );
};
