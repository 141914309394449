import { FC } from 'react';

import { ModuleScheduleItem } from '@common/clients/api';
import { BookmakerLogo } from '@web/atoms/BookmakerLogo';

import styles from '../ArticlePromoItem.module.scss';

export const IconBookmakerLogo: FC<{ icon: string; url: string; moduleScheduleItem: ModuleScheduleItem }> = ({
    icon,
    url,
    moduleScheduleItem,
}) => (
    <div className={styles['logo-wrapper']}>
        <BookmakerLogo alt={moduleScheduleItem.bookmaker?.name || ''} url={url} src={icon || ''} />
    </div>
);
